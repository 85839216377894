



























































































































































































import Vue from 'vue';
import { PreAlert } from '@/models/preAlert';
import { cloneDeep, omit } from 'lodash';
import { FormRules } from '@/utils/formRules';
import { axios } from '@/plugins/axios';
import { mapActions, mapState } from 'vuex';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '../common/ErrorAlert.vue';

import pdf from 'vue-pdf';
import UserLookup from '../staffnotification/UserLookup.vue';
import { Account } from '@/models/account';

export default Vue.extend({
  name: 'PreAlertForm',

  components: { pdf, ErrorAlert, UserLookup },

  mixins: [FormRules],

  data() {
    return {
      dialog: false,
      edit: false,
      valid: false,
      loading: false,
      isStaffPortal: false as boolean | undefined,

      invoice: null as any,
      preAlert: new PreAlert(),

      errorMessage: '',
      detailErrorMessage: ''
    };
  },

  computed: {
    ...mapState('account', ['account']),
    userID(): number {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    }
  },

  methods: {
    ...mapActions({
      createPreAlert: 'preAlert/createPreAlert',
      updatePreAlert: 'preAlert/updatePreAlert',
      uploadInvoice: 'shipment/uploadInvoice'
    }),

    open(preAlert = new PreAlert(), isEdit = false, staffPortal?: boolean) {
      this.clearAllErrors();
      this.preAlert = cloneDeep(preAlert);

      this.isStaffPortal = staffPortal;

      this.edit = isEdit;
      this.dialog = true;
    },

    close() {
      this.clearAllErrors();
      this.loading = false;
      this.dialog = false;
      this.invoice = null;
    },

    clearAllErrors() {
      this.detailErrorMessage = '';
      this.errorMessage = '';
    },

    async genPreAlert() {
      this.loading = true;

      const payload = cloneDeep(this.preAlert);

      if (!this.isStaffPortal) {
        payload.user = this.userID;
        payload.franchise = this.account.franchise.pk;
      }

      delete payload.invoices;

      this.createPreAlert(payload)
        .then((data) => {
          if (this.invoice != null) {
            const formData = new FormData();

            formData.append('package', '' as any);
            formData.append('preAlert', data.id as any);
            formData.append('images', this.invoice);

            this.uploadInvoice(formData)
              .then(() => {
                this.$emit('rebuild');
                this.close();
              })
              .catch((error) => {
                this.errorMessage = ErrorManager.extractApiError(error);
              });
          } else {
            this.close();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editPreAlert() {
      this.loading = true;

      const payload = cloneDeep(this.preAlert);

      if (payload.user && typeof payload.user == 'object') {
        payload.user = (payload.user as Account).pk;
      }

      payload.franchise = this.account.franchise.pk;
      delete payload.invoices;

      this.updatePreAlert(payload)
        .then((data) => {
          if (this.invoice != null) {
            const formData = new FormData();

            formData.append('package', '' as any);
            formData.append('preAlert', data.id as any);
            formData.append('images', this.invoice);

            this.uploadInvoice(formData)
              .then(() => {
                this.$emit('rebuild');
                this.close();
              })
              .catch((error) => {
                this.errorMessage = ErrorManager.extractApiError(error);
              });
          } else {
            this.close();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadImage(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios.get(url).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.preAlert.description} invoice.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    }
  }
});
