import { Account } from './account';
import { Franchise } from './franchise';
import { Invoices } from './shipment';

export class PreAlert {
  id?: number;
  tracking_number?: string;
  description?: string;
  shipper?: string;
  courier?: string;
  price?: number;
  fullfilled?: boolean;
  user?: number | string | Account | null = null;
  invoices?: Invoices[] | any = [];
  franchise?: Franchise;

  constructor() {
    this.user = new Account();
    this.franchise = new Franchise();
  }
}
