


































































import mixins from '@/utils/mixins';
import Functions from '@/mixins/functions';

import { Account, IAccountParams } from '@/models/account';
import { mapActions, mapState } from 'vuex';
import { PropType } from 'vue';
import { ErrorManager } from '@/models/error';

export default mixins(Functions).extend({
  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    multiple: {
      type: Boolean,
      default: () => true
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    value: [Array, Object],

    externalErrorMessage: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  data() {
    return {
      lazyValue: this.value,
      page: 1,
      loading: false,
      search: null,
      rules: [(v: any) => !!v || 'Field is required'],
      localUsersList: [] as Account[],
      errorMessage: ''
    };
  },

  computed: {
    ...mapState('user', ['user']),

    internalValue: {
      get(): any {
        return this.lazyValue;
      },
      set(val: any) {
        this.lazyValue = val;
        this.$emit('input', val);
      }
    },

    customAttrs(): any {
      return this.$attrs;
    },

    errorMessageList(): string[] {
      let errorList: string[] = [];
      if (this.externalErrorMessage) {
        errorList = this.externalErrorMessage;
      }
      if (this.errorMessage) {
        errorList.push(this.errorMessage);
      }

      return errorList;
    }
  },

  watch: {
    value: {
      handler(val: any, prevVal: any) {
        this.lazyValue = val;
        if (val) {
          if (val !== prevVal) {
            this.debouncefetchUsers();
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions({
      fetchUsers: 'user/fetchUsers',
      fetchUser: 'user/fetchUser'
    }),

    clearAllErrors(): void {
      this.errorMessage = '';
    },

    /**
     * Gets called when user clicks remove icon selected emoloyee
     *
     *
     * @param assignee text string to be searched
     *
     *
     */
    removeAssignee(employee: { pk: number; full_name: string; email: string }) {
      const index = this.internalValue.indexOf(employee.pk);
      if (index >= 0) this.internalValue.splice(index, 1);
    },

    setFilterUser(
      item: { full_name: string; email: string },
      queryText: string,
      itemText: any
    ) {
      return true;
    },

    debouncefetchUsers(search?: string) {
      this.clearAllErrors();
      // seach input is set to null in v-autocomplete
      if (search === null) return;

      this.loading = true;
      const params: IAccountParams = {
        page: 1,
        search: search,
        noStoreUpdate: true
      };
      this.debounce(this.searchUsers, 1000)(params);
    },

    searchUsers(params: IAccountParams) {
      this.loading = true;
      return this.fetchUsers(params)
        .then((data) => {
          this.localUsersList = data.results;

          for (let i = 0; this.internalValue.length > i; i++) {
            console.log(this.internalValue[i]);
            let currentEmployee;

            if (typeof this.internalValue[i] == 'object') {
              currentEmployee = this.localUsersList.find(
                (user) => this.internalValue[i].pk == user.pk
              );

              // Add current employee to list if not in initial list
              if (!currentEmployee) {
                this.fetchUser(this.internalValue[i].pk).then(
                  (data: Account) => {
                    this.localUsersList.unshift(data);
                  }
                );
              }
            } else {
              currentEmployee = this.localUsersList.find(
                (user) => this.internalValue[i] == user.pk
              );
              // Add current employee to list if not in initial list
              if (!currentEmployee && this.internalValue[i]) {
                this.fetchUser(this.internalValue[i]).then((data: Account) => {
                  this.localUsersList.unshift(data);
                });
              }
            }

            console.log(currentEmployee, this.internalValue[i]);
          }
        })
        .catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async mounted() {
    this.loading = true;

    this.fetchUsers()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
