var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',_vm._g(_vm._b({attrs:{"items":_vm.localUsersList,"filter":_vm.setFilterUser,"search-input":_vm.search,"clearable":"","hide-selected":"","item-value":"pk","placeholder":"Search for user...","multiple":_vm.multiple,"loading":_vm.loading,"outlined":"","dense":_vm.dense,"rules":_vm.rules,"hide-details":"auto"},on:{"update:search-input":_vm.debouncefetchUsers},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" No User Found ")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-document-box-search-outline ")])]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"primary","close":""},on:{"click:close":function($event){return _vm.removeAssignee(item)}}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}]),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }