



























































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import dateFormat from '@/utils/dateFormat';
import { ErrorManager } from '@/models/error';
import PreAlertForm from './PreAlertForm.vue';
import { PreAlert } from '@/models/preAlert';
import ErrorAlert from '../common/ErrorAlert.vue';

export default Vue.extend({
  components: { PreAlertForm, ErrorAlert },

  name: 'Pre-Alertst',

  data() {
    return {
      search: '',
      errorMessage: '',
      detailErrorMessage: '',

      loading: false,
      open: false,
      selectedPreAlert: new PreAlert(),
      deletePreAlertDialog: false,
    };
  },

  computed: {
    ...mapState('preAlert', ['preAlert']),

    userInfo() {
      return JSON.parse(localStorage.getItem('user')!);
    },

    totalPackagePrice(): string {
      let totalPackagePrice = 0;
      this.preAlert.forEach((preAlert: PreAlert) => {
        return (totalPackagePrice += Number(preAlert.price!));
      });
      return totalPackagePrice.toFixed(2);
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },

  methods: {
    ...mapActions({
      fetchPreAlerts: 'preAlert/fetchPreAlerts',
      deletePreAlert: 'preAlert/deletePreAlert',
    }),
    dateFormat,

    filterPreAlertObject(queryText: string) {
      const filter = this.preAlert.filter((element: PreAlert) => {
        return (
          element
            .description!.toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1
        );
      });
      return filter;
    },

    clearAllErrors() {
      this.detailErrorMessage = '';
      this.errorMessage = '';
    },

    openPreAlertFormDialog(preAlert: PreAlert) {
      (this as any).$refs.prealertform.open(preAlert, true);
    },

    openRemovePreAlertDialog(preAlert: PreAlert) {
      this.selectedPreAlert = preAlert;
      this.deletePreAlertDialog = true;
    },

    removePreAlert() {
      this.clearAllErrors();
      this.deletePreAlert(this.selectedPreAlert)
        .then(() => {
          this.genPreAlert();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deletePreAlertDialog = false;
        });
    },

    genPreAlert() {
      this.loading = true;
      this.clearAllErrors();
      this.fetchPreAlerts()
        .catch((error) => {
          if (error.response) {
            if (error.response.data.detail) {
              this.detailErrorMessage = error.response.data.detail;
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.genPreAlert();
  },
});
