import moment from 'moment';
const dateFormat = (
  date?: Date | string | number | (number | string)[],
  format?: string,
  utc = true
) => {
  const fmt = format || 'YYYY-MM-DD';
  return utc
    ? moment(date || new Date())
        .utc()
        .format(fmt)
        .toString()
    : moment(date || new Date())
        .local(true)
        .format(fmt)
        .toString();
};

export default dateFormat;
