import { Address } from './address';
import { Franchise } from './franchise';
import { UserAddress } from './user_address';

export class Account {
  pk?: number;
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  email2?: string;
  dob?: Date;
  trn?: string;
  verification_file?: string;
  role?: string;
  phone?: string;
  address?: Address;
  user_delivery_address?: UserAddress;
  franchise?: Franchise;

  constructor(data?: Account) {
    this.address = new Address();
    this.user_delivery_address = new UserAddress();
    this.franchise = new Franchise();
  }
}

export interface IAccountParams {
  role?: string;
  search?: string;
  page: number;
  noStoreUpdate?: boolean;
  franchise?: string;
}
