import { AxiosError } from 'axios';

export class ErrorManager {
  static extractApiError(error: AxiosError): string {
    if (error.response) {
      if (error.response.status == 401) {
        return 'Session has expired, please refresh page';
      }
      // client received an error response that falls out of range 2xx
      return error.response.statusText;
    } else if (error.request) {
      // The request was made but no response was received
      return 'No response from server';
    } else {
      // Something happened in setting up the request that triggered an Error
      return 'An error occurred communicating with server';
    }
  }
}

export interface IFieldErrorsData {
  [fieldName: string]: string[] | IFieldErrorsData;
}

export interface IFieldErrors extends IFieldErrorsData {
  [fieldName: string]: string[] | IFieldErrors;
}

export class FieldErrors implements IFieldErrors {
  [fieldName: string]: string[] | FieldErrors;

  constructor(data: IFieldErrorsData) {
    for (const fieldName in data) {
      if (Array.isArray(data[fieldName])) {
        // If the field error is an array of strings, directly assign it
        this[fieldName] = data[fieldName] as string[];
      } else {
        // If the field error is nested, create a new FieldErrors instance for that field
        this[fieldName] = new FieldErrors(data[fieldName] as IFieldErrorsData);
      }
    }
  }
}
