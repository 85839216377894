/* eslint-disable no-useless-escape */
export const FormRules = {
  data: () => ({
    rules: {
      required: (value: string) => !!value || 'This field is Required.',
      counter: (value: string) => value.length <= 20 || 'Max 20 characters',
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
      phone: (value: string) => {
        const pattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return pattern.test(value) || 'Format eg XXX-XXX-XXXX';
      },
      lowerCase: (value: string) => {
        const pattern = RegExp("(?=.*[a-z])");
        return pattern.test(value) || 'Password must contain at least 1 lowercase alphabetical character.'
      },
      upperCase: (value: string) => {
        const pattern = RegExp("(?=.*[A-Z])");
        return pattern.test(value) || 'Password must contain at least 1 uppercase alphabetical character.'
      },
      numeric: (value: string) => {
        const pattern = RegExp("(?=.*[0-9])");
        return pattern.test(value) || 'Password must contain contain at least 1 numeric character.'
      },
      character: (value: string) => {
        const pattern = RegExp("(?=.*[!@#$%^&*])");
        return pattern.test(value) || 'Password must contain at least one special character.'
      },
      lenght: (value: string) => {
        const pattern = RegExp("(?=.{8,})");
        return pattern.test(value) || 'Password must be eight characters or longer.'
      },
      size: (value: any) =>
        !value ||
        value.size < 2000000 ||
        'Avatar size should be less than 2 MB!'
      // startDateRule: (value )
    },
    computed: {
      match() {
        return (value: string) => !!value || 'Field Required.';
      }
    }
  })
};
