import { Account } from './account';

export class Franchise {
  pk?: number;
  name?: string;
  phone?: string;
  email?: string;
  street_1?: string;
  street_2?: string | null;
  city?: string;
  parish?: string;
  owners?: number[] | Account[];
  main_branch?: boolean;
  show_to_users?: boolean;
  phone_numbers?: FranchisePhoneNumber[] = [];
  abbreviation?: string;
  warehouses?: (number | Warehouse)[] = [];

  constructor(data?: Franchise) {
    if (data?.phone_numbers) {
      this.phone_numbers = data.phone_numbers.map(
        (phone_number) => new FranchisePhoneNumber(phone_number)
      );
    }

    if (data?.warehouses) {
      this.warehouses = data.warehouses.map((ware) => {
        if (typeof ware == 'number') {
          return ware;
        }
        return new Warehouse(ware);
      });
    }
  }
}

export class FranchisePhoneNumber {
  id?: number;
  franchise?: number;
  phone_number?: string;

  constructor(data: FranchisePhoneNumber) {
    if (data.id) {
      this.id = data.id;
    }
    if (data.franchise) {
      this.franchise = data.franchise;
    }
    if (data.phone_number) {
      this.phone_number = data.phone_number;
    }
  }
}

export class Warehouse {
  pk?: number;
  line_1?: string;
  line_2_abbreviation?: string;
  line_3?: string;
  name?: string;
  zipcode?: string;
  additional_details?: string;
  display_name?: string;

  constructor(data: Warehouse) {
    if (data.pk) {
      this.pk = data.pk;
    }
    if (data.line_1) {
      this.line_1 = data.line_1;
    }
    if (data.line_2_abbreviation) {
      this.line_2_abbreviation = data.line_2_abbreviation;
    }
    if (data.line_3) {
      this.line_3 = data.line_3;
    }
    if (data.name) {
      this.name = data.name;
    }
    if (data.zipcode) {
      this.zipcode = data.zipcode;
    }
    if (data.additional_details) {
      this.additional_details = data.additional_details;
    }

    this.display_name = data.display_name;
  }
}
